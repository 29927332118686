import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTimeoutFn } from 'react-use';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { useQueryParams, getEndpoint } from 'bat-components';

import style from './rating-bar.module.scss';

const barVariants = {
	initial: { y: '100%' },
	enter: { y: '0%', transition: { delay: 1, duration: 1 } },
	exit: {
		y: '100%',
		transition: { duration: 0.5 }
	}
};

const starVariants = {
	initial: {
		opacity: 0
	},
	enter: {
		opacity: 1
	},
	exit: {
		opacity: 0
	}
};

const selector = state => ({
	strings: state.content.getIn(['content', 'pages', 'single', 'strings']).toJS(),
	product: state.content.getIn(['content', 'product']).toJS()
});

export function RatingBar({ flavour }) {
	const { strings, product } = useSelector(selector);
	// const [, setSignUp] = useSignUp();
	const [rating, setRating] = useState(-1);
	const [sending, setSending] = useState(false);

	const { store } = useQueryParams();

	const stars = ['☆', '☆', '☆', '☆', '☆'];

	const onSubmit = async rating => {
		try {
			ReactGA.event({
				category: 'Rated Flavour', // required
				action: `${product.id} ${flavour.id}: ${rating}`, // required
				label: 'User rated a flavour' // optional
			});

			console.log(`${getEndpoint().api}/vote`);

			const res = await fetch(`${getEndpoint().api}/vote`, {
				method: 'POST',
				mode: 'cors',
				headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' },
				body: JSON.stringify({
					product: product.id,
					flavour: flavour.id,
					store,
					rating
				})
			});

			const body = res.json();
			console.log(body.messages);
		} catch (error) {
			return;
		}
	};

	const getIndex = target => {
		const siblings = [...target.parentNode.childNodes];
		const index = siblings.indexOf(target);

		return index;
	};

	const pointerDown = e => {
		e.preventDefault();

		setRating(getIndex(e.target));
	};

	const pointerUp = e => {
		e.preventDefault();

		onSubmit(rating + 1);

		setTimeout(() => {
			setSending(true);
		}, 500);
	};

	const pointerMove = e => {
		e.preventDefault();

		const { pageX, pageY } = e.touches[0];
		const elem = document.elementFromPoint(pageX, pageY);

		if (elem.matches(`.${style.star}`)) {
			setRating(getIndex(elem));
		}
	};

	useTimeoutFn(
		() => {
			setSending(false);
		},
		sending ? 3000 : null
	);

	return (
		<motion.div
			variants={barVariants}
			className={style.bar}
			style={{ '--flavour-color': flavour.color_flavour }}
		>
			<div className={style.opaque}>
				<AnimatePresence>
					{!sending ? (
						<motion.div
							initial="initial"
							variants={starVariants}
							animate="enter"
							exit="exit"
							className={style.opaqueInner}
							key="rating"
						>
							<div className={style.heading}>
								{strings.what_think} <span>{flavour.name}?</span>
							</div>

							<div className={style.rating}>
								<div
									className={style.stars}
									onTouchStart={pointerDown}
									onTouchMove={pointerMove}
									onTouchEnd={pointerUp}
								>
									<div className={style.flavourTitle}>
										<span>{strings.flavour_rating}</span>
									</div>

									<div className={style.starList}>
										{stars.map((star, i) => (
											<span key={i} className={style.star}>
												{i <= rating ? '★' : star}
											</span>
										))}
									</div>
									<div className={style.markers}>
										<span className={style.dislike}>{strings.dislike}</span>
										<span className={style.like}>{strings.like}</span>
									</div>
								</div>
							</div>
						</motion.div>
					) : (
						<motion.div
							className={style.confirm}
							initial="initial"
							animate="enter"
							exit="exit"
							variants={starVariants}
							key="tick"
						>
							<svg
								className={style.checkmark}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 52 52"
							>
								<circle className={style.checkmark__circle} cx="26" cy="26" r="25" fill="none" />
								<path
									className={style.checkmark__check}
									fill="none"
									d="M14.1 27.2l7.1 7.2 16.7-16.8"
								/>
							</svg>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			{/* <div className={style.transparent}>
				<Button
					variant="invert"
					onClick={() => {
						setSignUp(true);
					}}
				>
					{strings.sign_up}
				</Button>
			</div>
			<SignUp></SignUp> */}
		</motion.div>
	);
}
