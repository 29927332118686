import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Button, VideoPlayer, useVideoPlayer } from 'bat-components';
import ReactGA from 'react-ga';

import style from './video-tile.module.scss';

import buttonImg from 'assets/images/button.png';

const variants = {
	initial: {
		x: 50,
		opacity: 0
	},
	enter: {
		x: 0,
		opacity: 1,
		transition: {
			duration: 1
		}
	},
	exit: {
		opacity: 0,
		x: 50,
		transition: {
			duration: 0.5
		}
	}
};

export function VideoTile({ video, watchStr = 'watch', locale, theme, copyrights, rightWarning }) {
	const [playerOpen, openVideoPlayer] = useVideoPlayer(video.src);

	useEffect(() => {
		if (playerOpen) {
			ReactGA.event({
				category: 'Played Video', // required
				action: video.name, // required
				label: 'User played a video' // optional
			});
		} else {
			ReactGA.event({
				category: 'Closed Video', // required
				action: video.name, // required
				label: 'User closed a video' // optional
			});
		}
	}, [playerOpen, video.name]);

	return (
		<>
			<motion.div
				initial="initial"
				variants={variants}
				className={style.tile}
				onClick={() => openVideoPlayer(true)}
			>
				<div className={style.thumb}>
					<img src={video.thumbnail} alt="" />
					<Button variant="img" variants={null} className={style.imgButton}>
						<img src={buttonImg} alt="" />
					</Button>
				</div>
				<div className={style.title}>
					{watchStr}: <span>{video.name}</span>
				</div>
			</motion.div>
			<VideoPlayer
				name={video.src}
				src={video.src}
				textColorOverride={video.text_color_override}
				backgroundColor={video.modal_background_color}
				isRightAlignCloseButton={true}
				copyrights={copyrights}
				rightWarning={rightWarning}
				theme={theme}
			></VideoPlayer>
		</>
	);
}
