import React from 'react';
import { motion } from 'framer-motion';

import style from './dot-navigation.module.scss';
import clsx from 'clsx';

const wrapVaraints = {
	initial: {
		zIndex: 0,
		transition: {
			staggerChildren: 0.02,
			when: 'beforeChildren'
		}
	},
	slideIn: {
		zIndex: 5,
		transition: {
			delay: 1,
			staggerChildren: 0.03,
			when: 'beforeChildren'
		}
	}
};

const itemVariants = {
	initial: {
		x: -100,
		opacity: 0
	},
	slideIn: {
		x: 0,
		opacity: 1
	}
};

const positionOnCircle = ({ x, y }, radius, totalItems, index, display_flavour_names = false) => {
	const availableSpaces = display_flavour_names ? 14 : 18;

	const arcSegment = Math.PI / 2 / availableSpaces; // Pi / 2 = quarter circle. Divide by 18 for 18 available spaces
	const angle = arcSegment * index;
	const adj = (arcSegment * (totalItems - 1)) / 2;
	// const adj = 0;

	const xPos = x + radius * Math.cos(angle - adj); // angle in radians
	const yPos = y + radius * Math.sin(angle - adj);

	return { x: xPos, y: yPos };
};

export function DotNavigation({
	flavours,
	selected = 1,
	setFirstStart,
	setSelected,
	show,
	disabled,
	display_flavour_names = false
}) {
	return (
		<motion.div
			initial="initial"
			animate={show ? 'slideIn' : 'initial'}
			variants={wrapVaraints}
			className={style.container}
		>
			{flavours.map((flav, i) => {
				const pos = positionOnCircle(
					{ x: 0, y: 0 },
					0.6 * window.innerHeight + 30, // Radius of the 120vh container, basically (plus a bit)
					flavours.length,
					i,
					display_flavour_names
				);
				return (
					<motion.div
						className={style.dotPosition}
						variants={itemVariants}
						key={i}
						style={{
							left: pos.x,
							top: pos.y,
							'--dotColor': flav.color_flavour
						}}
					>
						<div
							className={clsx(style.dot, { [style.selected]: selected === i })}
							onClick={e => {
								if (disabled) {
									return e.preventDefault();
								}
								setSelected(i);
								display_flavour_names && setFirstStart(true);
							}}
						>
							{display_flavour_names && (
								<span className={style.flavourName}>{flav.name_override || flav.name}</span>
							)}
						</div>
					</motion.div>
				);
			})}
		</motion.div>
	);
}
