import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { changeTradeMark } from 'helpers';

import style from './product.module.scss';

export function WheelProduct({ content, firstStart, selected, locale }) {
	const image = content.flavours[selected].product_image
		? content.flavours[selected].product_image
		: content.product.image;

	const imageOverride = content.product.image;
	const exitDuration = ['za', 'uk'].includes(locale) ? 0.6 : 1.2;

	let productNameTitle = (
		<div
			dangerouslySetInnerHTML={{
				__html: changeTradeMark(content.product.name, 'trademark')
			}}
			className={style.productNameTitle}
		/>
	);

	if (['za', 'uk'].includes(locale) && firstStart) {
		productNameTitle = null;
	}

	const imageToDisplay = firstStart ? image : imageOverride;

	console.log(content.productImageSizeOverride);
	return (
		<>
			<div
				className={style.productImgContainer}
				style={
					content.productImageSizeOverride ? { height: `${content.productImageSizeOverride}%` } : {}
				}
			>
				<AnimatePresence>
					{imageToDisplay && (
						<motion.img
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { ease: 'easeOut', duration: 0.8 } }}
							exit={{ opacity: 0, transition: { duration: exitDuration } }}
							src={imageToDisplay}
							alt=""
							className={style.productImg}
							key={image}
						/>
					)}
				</AnimatePresence>
			</div>
			<div className={style.productName}>
				{!content.hideProductName && productNameTitle}
				{firstStart && !['uk'].includes(locale) && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						className={style.productNameAfter}
					>
						{content.main_strings.find_your_flavour}
					</motion.div>
				)}
			</div>
		</>
	);
}

export function SpinGraphic({ show, img, children }) {
	return (
		<AnimatePresence>
			{show && (
				<>
					<motion.img
						className={style.spinGraphic}
						src={img}
						alt=""
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					/>
					{children}
				</>
			)}
		</AnimatePresence>
	);
}
