import { Map, fromJS } from 'immutable';

export const content = {
	state: Map({
		ratingBarVisibility: null
	}),
	reducers: {
		setInitialState(state, payload) {
			return state.setIn(['content'], fromJS(payload));
		},
		setRatingBarVisibility(state, payload) {
			return state.setIn(['content', 'ratingBarVisibility'], payload);
		}
	},
	effects: {}
};
