export default state => ({
	background: state.content.getIn(['content', 'pages', 'main', 'background']).toJS(),
	main_strings: state.content.getIn(['content', 'pages', 'main', 'strings']).toJS(),
	single_strings: state.content.getIn(['content', 'pages', 'single', 'strings']).toJS(),
	product: state.content.getIn(['content', 'product']).toJS(),
	flavours: state.content.getIn(['content', 'flavours']).toJS(),
	spinGraphic: state.content.getIn(['content', 'pages', 'main', 'spin_graphic']),
	locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
	copyrights: state.content.getIn(['content', 'copyrights'])?.toJS(),
	warnings: state.content.getIn(['content', 'warnings'])?.toJS(),
	theme: state.internal.getIn(['theme']),
	hideLogo: state.content.getIn(['content', 'pages', 'main', 'hideLogo']) || false,
	hideProductName: state.content.getIn(['content', 'pages', 'main', 'hideProductName']) || false,
	productImageSizeOverride:
		state.content.getIn(['content', 'pages', 'main', 'productImageSizeOverride']) || false,
	spin_display: state.content.getIn(['content', 'pages', 'main', 'spin_display']) || 'default',
	display_flavour_names:
		state.content.getIn(['content', 'pages', 'main', 'display_flavour_names']) || false,
	spin_bg: state.content.getIn(['content', 'pages', 'main', 'spin_bg']),
	realTheme: state.content.getIn(['content', 'product', 'theme']),
	features: state.content.getIn(['content', 'product', 'theme']),
	style_override: state.content.getIn(['content', 'pages', 'main', 'style_override'])?.toJS() || {}
});
