import 'core-js/features/promise/all-settled';
import 'core-js/stable';

import React from 'react';
import { Provider, init } from 'bat-components';

import App from 'components/App/App';
import * as models from 'model';

import 'styles/main.scss';
import 'bat-components/dist/style.css';

const config = {
	analytics: true,
	debug_manifest: true,
	screenType: 'qb13r',
	socket: {
		overrides: {
			url: 'http://192.168.176.112:4000',
			room: 'dev' // storeId
		}
	}
};

init(
	'/data-velo-de.json',
	<Provider models={models} config={config}>
		<App />
	</Provider>
);
