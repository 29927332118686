import React, { useRef, useEffect, useState, useMemo } from 'react';
import { TrialSpinner } from 'bat-components';
import { motion, AnimatePresence } from 'framer-motion';

import { TweenMax, Draggable } from 'gsap/all';
import 'libs/ThrowPropsPlugin';

import style from './spin-wheel.module.scss';

function modulo(x, n) {
	return ((x % n) + n) % n;
}

function noop() {
	return;
}

function getFlavourMod(flavours) {
	switch (flavours.length) {
		case 2:
			return [...flavours, ...flavours, ...flavours];

		case 3:
		case 4:
		case 5:
			return [...flavours, ...flavours];

		default:
			return flavours;
	}
}

export function Wheel({
	onRest = noop,
	onStart = noop,
	onChange,
	flavours: flavoursPreMod,
	forcedSelection,
	children,
	showChildren,
	moving,
	firstStart,
	displayType = 'default'
}) {
	const flavours = useMemo(() => getFlavourMod(flavoursPreMod), [flavoursPreMod]);
	const snapAngle = 360 / flavours.length;
	const halfAngle = snapAngle / 2;

	const spinnerRef = useRef();
	const dragRef = useRef();
	const varRef = useRef({ moving, firstStart });

	const [trueSelectedIndex, setTrueSelected] = useState(0);
	const [touched, setTouched] = useState(false);

	const onPress = function () {
		if (varRef.current.firstStart) {
			setTouched(true);
		}
	};

	const onRelease = function () {
		if (!varRef.current.moving) {
			setTouched(false);
		}
	};

	const onDragStart = function () {
		setTouched(true);
		onStart();
	};

	const onThrowComplete = function () {
		const { rotation } = this;

		TweenMax.set(spinnerRef.current, { rotation: modulo(rotation, 360) });
		dragRef.current[0].update();

		setTouched(false);

		onRest();
	};

	const onUpdate = function () {
		const { rotation } = this;

		const m = modulo(rotation, 360);
		const selectionIndex = Math.floor((m * flavours.length) / 360);

		const updateIndex = flavours.length - selectionIndex - 1;
		onChange(updateIndex % flavoursPreMod.length);
		setTrueSelected(updateIndex);
	};

	useEffect(() => {
		varRef.current = { moving, firstStart };
	}, [moving, firstStart]);

	useEffect(() => {
		TweenMax.set(spinnerRef.current, {
			rotation: -(snapAngle * trueSelectedIndex) - halfAngle,
			scale: displayType === 'circles' ? 1.17 : 1
		});

		dragRef.current = Draggable.create(spinnerRef.current, {
			type: 'rotation',
			onPress,
			onRelease,
			onDragStart,
			onThrowComplete,
			onDrag: onUpdate,
			onThrowUpdate: onUpdate,
			throwProps: true,
			snap: endValue => Math.round(endValue / snapAngle) * snapAngle + halfAngle
		});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (forcedSelection >= 0) {
			const angle = modulo(-(forcedSelection * snapAngle) - halfAngle, 360);
			TweenMax.to(spinnerRef.current, 0.5, { rotation: angle + '_short' });
			dragRef.current[0].update();

			setTrueSelected(forcedSelection);
			onChange(forcedSelection);
		}
	}, [forcedSelection, halfAngle, onChange, snapAngle]);

	return (
		<div className={style.container}>
			<div className={style.spinner} ref={spinnerRef}>
				<div
					className={style.inner}
					style={{
						transform: `rotate(${90 + halfAngle}deg)`
					}}
				>
					<TrialSpinner
						highlight={touched ? null : trueSelectedIndex}
						spinnerData={flavoursPreMod}
						displayType={displayType}
					/>
				</div>
			</div>
			<AnimatePresence>
				{showChildren && (
					<motion.div
						className={style.injected}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { delay: 1 } }}
						exit={{ opacity: 0 }}
					>
						<div className={style.injectedChild}>{children}</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}
