import React, { useEffect, useState } from 'react';
import {
	Layout,
	useQueryParams,
	useBackground,
	useSocket,
	WarningText,
	Copyrights,
	useTheme
} from 'bat-components';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import { AnimatePresence, motion } from 'framer-motion';
import { useInterval, useMount } from 'react-use';

import { VideoTile, IntroBlock, InfoBlock, StartTrial as TextTile } from 'components/atoms';
import { RatingBar } from 'components/molecules';
import { WheelHalf } from 'components/organisms';

import clsx from 'clsx';

import selector from './Main.selector.js';

import style from './main.module.scss';

// The "Main" screen of the app. This probably should be built with routes rather than how it is but...well.
// There's three important components here:
// 		WheelHalf holds the wheel and that is permanent - just shrinking and growing depending on screen
// 		MainRight holds the info content when you're spinning the wheel
// 		SingleRight holds details of a single product

export function Main() {
	const content = useSelector(selector);
	const { trial_wall_variant: screenVariation = '1' } = useQueryParams();
	const { sendToServer, listener } = useSocket();

	const [firstStart, setFirstStart] = useState(false);
	const [moving, setMoving] = useState(false);
	const [showSingle, setShowSingle] = useState(false);
	const [selected, setSelected] = useState(0);
	const [, forceUpdate] = useState();
	const [endInteractive, setEndInteractive] = useState(false);

	const selectedItem = content.flavours[selected];

	useBackground(content.background);

	useTheme(content.realTheme);

	useMount(() => {
		if (!endInteractive) {
			sendToServer('pingActive', {
				kioskIndex: Number(screenVariation) - 1
			});
		}

		listener('handleEndInteractive', response => {
			setEndInteractive(response);
			forceUpdate();
		});
	});

	useInterval(() => {
		if (!endInteractive) {
			sendToServer('pingActive', {
				kioskIndex: Number(screenVariation) - 1
			});
		}
	}, 5000);

	const showSelectedDetails = show => {
		setShowSingle(show);

		if (show && selectedItem?.interactive_content?.id) {
			// Send the command to GA and to the node server that someone has clicked through to the details on an item.
			// Don't do anything if interactive_content doesn't have an id - this is useful in places like Poland where Lyft never has any Trial TV content
			ReactGA.event({
				category: 'Navigation', // required
				action: selectedItem.interactive_content.id, // required
				label: 'User has navigated to see more details' // optional
			});

			// for non-US markets, update TV content on button click
			// if (content.locale !== 'us' || content.locale !== 'za') {
			if (content.locale !== 'us' && content.locale !== 'uk') {
				sendToServer('playInteractive', {
					kioskIndex: Number(screenVariation) - 1,
					kioskSpan: selectedItem.interactive_content.span || 1,
					interactiveContent: selectedItem.interactive_content.id
				});
			}
		}
	};

	return (
		<Layout
			data-display={content.spin_display}
			data-options-layout={content.display_flavour_names ? 'withFlavours' : ''}
			style={{
				...{ color: 'var(--theme-color-primary)' },
				...content.main_strings.style_override
			}}
		>
			{content.locale === 'us' && (
				<WarningText
					leftWarning="Underage Sale Prohibited"
					rightWarning={content.warnings.right_warning}
					rightWarningColorOverride={content.warnings.color_override}
				/>
			)}
			<div className={style.wheel}>
				<WheelHalf
					content={content}
					showSingle={showSingle}
					setSelected={setSelected}
					setFirstStart={setFirstStart}
					firstStart={firstStart}
					selected={selected}
					moving={moving}
					setMoving={setMoving}
					locale={content.locale}
				></WheelHalf>
			</div>

			<AnimatePresence exitBeforeEnter>
				{showSingle ? (
					<SingleRight
						selectedItem={selectedItem}
						content={content}
						showSelectedDetails={showSelectedDetails}
						key="single"
					></SingleRight>
				) : (
					<MainRight
						firstStart={firstStart}
						content={content}
						moving={moving}
						selected={selected}
						showSelectedDetails={showSelectedDetails}
						key="main"
					></MainRight>
				)}
			</AnimatePresence>

			{content.locale === 'us' && (
				<Copyrights
					leftCopyright={
						content.theme === 'vuse' ? content.copyrights.vuse : content.copyrights.velo
					}
				/>
			)}
		</Layout>
	);
}

const mainRightVariants = {
	initial: { opacity: 0 },
	enter: { opacity: 1 },
	exit: { opacity: 0 }
};

function MainRight({ firstStart, content, moving, selected, showSelectedDetails }) {
	const selectedItem = content.flavours[selected];
	const { trial_wall_variant: screenVariation = '1' } = useQueryParams();
	const { sendToServer } = useSocket();

	// for US market, update TV content when spinner settles on an item
	// (uses a timeout to avoid updating too frequently as the user spins)
	useEffect(() => {
		// if ((content.locale === 'us' || content.locale === 'za') && firstStart) {
		if (['za', 'uk', 'us', 'de'].includes(content.locale) && firstStart) {
			if (selectedItem.interactive_content) {
				const timer = setTimeout(() => {
					// if (content.locale === 'za' || content.locale === 'de') {
					sendToServer('playInteractive', {
						kioskIndex: Number(screenVariation) - 1,
						kioskSpan: selectedItem.interactive_content.span || 1,
						interactiveContent: selectedItem.interactive_content.id
					});
					// }
				}, 1000);
				return () => clearTimeout(timer);
			}
		}
		// eslint-disable-next-line
	}, [
		content.locale,
		firstStart,
		sendToServer,
		screenVariation,
		// eslint-disable-next-line
		selectedItem.interactive_content?.span,
		// eslint-disable-next-line
		selectedItem.interactive_content?.id
	]);

	console.log(content);

	return (
		<motion.div
			className={style.contentContainer}
			initial="initial"
			animate="enter"
			exit="exit"
			variants={mainRightVariants}
		>
			<div
				className={clsx(style.content, {
					[style.contentLarge]: ['us', 'za'].includes(content.locale) && !firstStart
				})}
				style={content.style_override}
			>
				<AnimatePresence>
					{firstStart ? (
						<InfoBlock
							content={content.flavours}
							product={content.product}
							strings={content.main_strings}
							moving={moving}
							selected={selected}
							showSelectedDetails={showSelectedDetails}
							locale={content.locale}
							theme={content.theme}
							hideLogo={content.hideLogo}
						></InfoBlock>
					) : (
						<IntroBlock
							logo={content.product.logo}
							content={content.main_strings}
							hideLogo={content.hideLogo}
						></IntroBlock>
					)}
				</AnimatePresence>
			</div>
		</motion.div>
	);
}

const singleButtonVariants = {
	initial: { opacity: 0 },
	enter: { opacity: 1, transition: { delay: 1 } },
	exit: { opacity: 0 }
};

const videoTileVariants = {
	enter: {
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.3,
			delayChildren: 0.5
		}
	},
	exit: {
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.1,
			staggerDirection: -1
		}
	}
};

function getTiles(data, type) {
	switch (type) {
		case 'video':
			return (
				<VideoTile
					video={data}
					key={`${data.selectedItem?.id ?? data.thumbnail}-video`}
					locale={data.locale}
					copyrights={data.copyrights}
					rightWarning={data.warnings?.right_warning}
					theme={data.theme}
				/>
			);
		case 'cta':
			return (
				<TextTile
					flavour={data.selectedItem}
					strings={data.main_strings}
					theme={data.theme}
					key={`${data.selectedItem?.id ?? ''}-text`}
					tile={data.tile}
				/>
			);
		default:
			return <></>;
	}
}

function SingleRight({ selectedItem, content, showSelectedDetails }) {
	const dispatch = useDispatch();

	const goBack = () => {
		showSelectedDetails(false);
		dispatch.content.setRatingBarVisibility(false);

		ReactGA.event({
			category: 'Navigation', // required
			action: 'Back to wheel', // required
			label: 'User has navigated back to the main page' // optional
		});
	};

	return (
		<motion.div className={style.singleRight} initial="initial" animate="enter" exit="exit">
			<motion.div variants={singleButtonVariants} className={style.backLabel}>
				<button onClick={goBack}>{content.single_strings.try_another}</button>
			</motion.div>

			<motion.div variants={videoTileVariants} className={style.videos}>
				{selectedItem.tiles?.map(tile => {
					const data = { selectedItem, tile, ...content };
					return getTiles(data, tile.type);
				})}

				{selectedItem.videos?.map(video => {
					const data = { ...video, ...content };
					return getTiles(data, 'video');
				})}

				{['us', 'za'].includes(content.locale) && !selectedItem.tiles && (
					<TextTile flavour={selectedItem} strings={content.main_strings} theme={content.theme} />
				)}
			</motion.div>
			<RatingBar flavour={selectedItem} strings={content.single_strings}></RatingBar>
		</motion.div>
	);
}
