import React from 'react';
import { motion } from 'framer-motion';

import style from './intro-block.module.scss';

const variants = {
	initial: { opacity: 0, y: 50 },
	enter: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: -50 }
};

const Icons = ({ content }) => {
	return (
		<div className={style.icons}>
			{content.icons.map(icon => (
				<img src={icon} alt={icon} key={icon}></img>
			))}
		</div>
	);
};

export function IntroBlock({ content, logo, hideLogo }) {
	return (
		<motion.div
			initial="initial"
			animate="enter"
			exit="exit"
			variants={variants}
			transition={{ duration: 0.6 }}
			style={{ marginBottom: 50 }}
		>
			{!hideLogo && <img src={logo} alt="" className={style.logo} />}
			<div className={style.text} style={{ ...content.style_override_main_text_block }}>
				{content.text}
			</div>
			<div className={style.instructions}>{content.instructions}</div>
			{content.icons && <Icons content={content} />}
		</motion.div>
	);
}
