import React from 'react';
import { useDispatch } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Modal, useModal, VideoPlayer, useVideoPlayer } from 'bat-components';
import ReactGA from 'react-ga';
import clsx from 'clsx';

import { NicotineLevels } from 'components/atoms';
import { switchingVariants, infoVariants } from './InfoBlock.animations.js';
import style from './info-block.module.scss';

export function InfoBlock({
	content,
	product,
	strings,
	selected,
	moving,
	showSelectedDetails,
	locale,
	theme,
	hideLogo
}) {
	const selectedContent = content[selected];
	const hasSubContent =
		selectedContent.videos?.length || selectedContent.tiles?.length || locale === 'us';

	const isVuseUs = locale === 'us' && theme === 'vuse';
	const isInvertedButton =
		['vuse', 'vuse-epod', 'vuse-epen'].includes(theme) && ['us', 'uk'].includes(locale);

	const isVeloAndZa = theme === 'velo' && locale === 'za';
	const dispatch = useDispatch();

	return (
		<motion.div
			initial={{ opacity: 0, y: -50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.6 }}
			className={style.block}
			style={{
				'--flavour-color': selectedContent.color_flavour,
				height: product.override_height || undefined
			}}
		>
			<AnimatePresence>
				{!moving && (
					<motion.div
						variants={switchingVariants}
						initial="initial"
						animate="enter"
						exit="exit"
						key={selectedContent.name}
						className={clsx(style.blockInside, {
							[style.hasSubContent]: hasSubContent
						})}
					>
						{!hideLogo && (
							<div className={style.logoContainer}>
								<img src={product.logo} alt="" className={style.logo} />
								{product.slogan && <div className={style.slogan}>{product.slogan}</div>}
							</div>
						)}

						<div className={style.switching}>
							<div className={style.nameWrapper}>
								{selectedContent.category && (
									<div className={style.type}>{selectedContent.category}</div>
								)}
								{selectedContent.product_type && (
									<div className={style.productType}>{selectedContent.product_type}</div>
								)}
								<div
									style={{ color: isVeloAndZa ? `${product.color_flavour} !important` : null }}
									className={style.name}
								>
									{selectedContent.name}
								</div>

								<div className={style.drip}>
									<AnimatePresence>
										{!moving && selectedContent.drip && (
											<motion.img
												src={selectedContent.drip}
												className={style.dripImg}
												alt=""
												variants={infoVariants}
												initial="initial"
												animate="enter"
												exit="initial"
											/>
										)}
									</AnimatePresence>
								</div>
							</div>

							<div className={style.info}>{selectedContent.descriptor}</div>
						</div>

						<div className={style.variantsWrapper}>
							{selectedContent.prices_title && (
								<div className={style.variantTitle}>{selectedContent.prices_title}</div>
							)}
							{selectedContent.product_price && (
								<div className={style.optionWrapper}>
									{selectedContent.product_price.map(option => {
										return (
											<div className={style.optionPriceContainer} key={option.title}>
												<span className={style.optionTitle}>{option.title}</span>
												<span className={style.optionPrice}>{option.price}</span>
											</div>
										);
									})}
								</div>
							)}
							<div className={style.variants}>
								{selectedContent.variants.map((item, i) => (
									<VariantBlock
										key={i}
										flavour={selectedContent}
										item={item}
										strings={strings}
										locale={locale}
										theme={theme}
										bars={selectedContent.bars ?? false}
										textOnly={selectedContent.nic_level_text_only ?? false}
									/>
								))}
							</div>

							{selectedContent.features && (
								<FeatureModal
									features={selectedContent.features.text}
									locale={locale}
									flavour={selectedContent}
									strings={strings}
									title={selectedContent.features.title}
									modal_background={'white'}
								/>
							)}
						</div>

						{hasSubContent ? (
							<div className={style.button}>
								<Button
									className={(style.tryButton, { [style.invertDE]: locale === 'de' })}
									variant={isInvertedButton ? 'invert' : 'primary'}
									size="normal"
									onClick={() => {
										showSelectedDetails(true);
										dispatch.content.setRatingBarVisibility(true);
									}}
								>
									{isVuseUs ? `Try ${selectedContent.name}` : strings.try_button}
								</Button>
							</div>
						) : null}
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
}

function VariantBlock({ flavour, item, strings, locale, variant = 'ghost', bars, textOnly }) {
	const [, openVideoPlayer] = useVideoPlayer(flavour.video_modal?.name);

	return (
		<div
			className={style.variantBlock}
			style={{
				'--theme-color': flavour.color_flavour
			}}
		>
			{item.title && <div className={style.variantTitle}>{item.title}</div>}
			{['uk'].includes(locale) && <div className={style.vgSubtitle}>{item.vg}</div>}
			{item.price && (
				<div className={style.variantPrice}>
					<span>
						{item.price}
						<span className={style.priceDetail}>{item.price_detail}</span>
					</span>
					{locale === 'us' && !strings.hide_plus_tax && (
						<span className={style.plusTax}>
							<span>plus</span>
							<span>tax</span>
						</span>
					)}
				</div>
			)}
			<NicotineLevels item={item} strings={strings} bars={bars} textOnly={textOnly} />
			{flavour.video_modal && (
				<div className={style.btnContainer}>
					<Button
						variant="primary"
						onClick={() => openVideoPlayer(true)}
						className={clsx(locale === 'za' && style.smallStyle)}
						analytics={{
							category: 'Watch the Video',
							action: `button clicked`,
							label: `User views product video`
						}}
					>
						{flavour.video_modal?.btn_text || ''}
					</Button>

					<VideoPlayer
						isRightAlignCloseButton={true}
						name={flavour.video_modal?.name || ''}
						src={flavour.video_modal.src}
						backgroundColor={'black'}
						videoModal
					></VideoPlayer>
				</div>
			)}
			{item.features && (
				<FeatureModal
					features={item.features}
					variant={variant}
					locale={locale}
					flavour={flavour}
					strings={strings}
					title={flavour.name}
					vg={item.vg}
					modal_background={
						strings.modal_background ? strings.modal_background : item.modal_background
					}
				/>
			)}
		</div>
	);
}

const FeatureModal = ({
	features,
	variant,
	locale,
	flavour,
	strings,
	title,
	modal_background,
	vg
}) => {
	const [, openModal] = useModal('info');

	const moreClick = () => {
		ReactGA.event({
			category: 'Clicked More', // required
			action: `${flavour.id} / ${title}`, // required
			label: 'Use rotated the wheel to a position' // optional
		});

		openModal(true);
	};

	const getModalTheme = locale => {
		switch (locale) {
			case 'us':
				return 'image';
			case 'uk':
			case 'za':
			case 'de':
				return 'light';
			default:
				return 'black';
		}
	};

	const isMedium = ['us', 'za', 'uk', 'de'].includes(locale);

	return (
		<>
			{features && (
				<Button variant={variant} size="small" className={style.variantBtn} onClick={moreClick}>
					{strings.more}
				</Button>
			)}

			<Modal
				name="info"
				theme={getModalTheme(locale)}
				medium={isMedium}
				small={!isMedium}
				backgroundImg={modal_background}
				backgroundColor="white"
			>
				<div
					className={style.modal}
					style={{
						'--theme-color': flavour.color_flavour
					}}
				>
					<div className={style.modalTitle}>{title}</div>
					<div className={style.modalVG}>{vg}</div>
					<div className={style.modalFeatures}>{features}</div>
					<div className={style.modalAdvice}>{flavour.advice}</div>
				</div>
			</Modal>
		</>
	);
};
