import React, { useState } from 'react';
import style from './startTrial.module.scss';
import clsx from 'clsx';
import { Button } from 'bat-components';
import { motion } from 'framer-motion';

export function StartTrial({ flavour, strings, theme, tile = {} }) {
	const [trialStarted, setTrialStarted] = useState(false);

	const noVideo = flavour?.videos?.length === 0;
	const comingSoon = theme === 'vuse';
	const isVuse = theme === 'vuse';

	const containerVariants = {
		initial: {
			x: 50,
			opacity: 0
		},
		enter: {
			x: 0,
			opacity: 1,
			transition: {
				duration: 1
			}
		}
	};

	return (
		<motion.div
			initial="initial"
			variants={containerVariants}
			className={clsx(style.container, { [style.noVideo]: noVideo })}
		>
			<div className={style.startTrialInner}>
				{flavour.drip && (
					<img src={flavour.drip} alt={flavour.name} className={style.flavourIcon} />
				)}

				{trialStarted ? (
					<div className={clsx(style.mainTextTrialStarted)}>{strings.trial_started}</div>
				) : (
					<>
						{/* Harcoded for US*/}
						{!tile.title && <div className={style.mainText}>Would you like to try</div>}

						<div className={style.flavourName} style={{ '--flavour-color': flavour.color_flavour }}>
							{flavour.name}
							{/* Harcoded for US*/} {!tile.title && <span className={style.questionMark}>?</span>}
						</div>
						{tile.title && <div className={style.mainText}>{tile.title}</div>}
						<div className={style.tryFlavor}>
							{tile.copy || strings.try_flavor}
							{isVuse && !strings.hide_plus_tax && (
								<span className={style.plusTax}>
									<span>plus</span>
									<span>tax</span>
								</span>
							)}
						</div>
					</>
				)}
			</div>

			{comingSoon ? (
				<div className={style.comingSoon}>Coming Soon</div>
			) : (
				<Button
					onClick={() => setTrialStarted(!trialStarted)}
					variant={isVuse ? 'invert' : 'primary'}
					className={style.startTrialButton}
				>
					{trialStarted ? 'Cancel' : tile.button_text ? tile.button_text : 'Start Trial'}
				</Button>
			)}
		</motion.div>
	);
}
