export const switchingVariants = {
	initial: {
		opacity: 0
	},
	enter: {
		opacity: 1,
		transition: {
			duration: 0.3
		}
	},
	exit: {
		opacity: 0
	}
};

export const infoVariants = {
	initial: { opacity: 0 },
	enter: { opacity: 1, transition: { delay: 0.2 } }
};
