import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga';

import { Wheel } from 'components/molecules';
import { WheelProduct, SpinGraphic, DotNavigation } from 'components/atoms';
import style from './wheel-half.module.scss';
import { getShrinkVariants } from './WheelHalf.animations';

export function WheelHalf({
	content,
	showSingle,
	setSelected,
	setFirstStart,
	firstStart,
	selected,
	moving,
	setMoving,
	locale
}) {
	const [forcedSelection, setForcedSelection] = useState(-1);

	const shrinkVariants = getShrinkVariants(
		locale,
		content.spin_display,
		content.display_flavour_names
	);

	const onStart = () => {
		setFirstStart(true);
		setMoving(true);

		setForcedSelection(-1);
	};

	const onRest = () => {
		setMoving(false);

		ReactGA.event({
			category: 'Rested on', // required
			action: content.flavours[selected].id, // required
			label: 'User rotated the wheel to a position' // optional
		});
	};

	return (
		<>
			<div className={style.wheel}>
				<motion.div
					variants={shrinkVariants}
					animate={showSingle ? 'shrunk' : 'initial'}
					exit="initial"
				>
					<Wheel
						onRest={onRest}
						onStart={onStart}
						onChange={setSelected}
						flavours={content.flavours}
						forcedSelection={forcedSelection}
						showChildren={!showSingle}
						moving={moving}
						firstStart={firstStart}
						displayType={content.spin_display}
					>
						{content.spin_bg && (
							<motion.img
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { ease: 'easeOut', duration: 0.8 } }}
								exit={{ opacity: 0, transition: { duration: 0.8 } }}
								src={content.spin_bg}
								alt=""
								className={style.productImgBg}
							/>
						)}
						<WheelProduct
							firstStart={firstStart}
							content={content}
							selected={selected}
							locale={locale}
						></WheelProduct>
					</Wheel>
					<SpinGraphic show={!moving && !showSingle} img={content.spinGraphic}></SpinGraphic>
				</motion.div>
			</div>
			<DotNavigation
				show={(firstStart && !showSingle) || (!showSingle && content.display_flavour_names)}
				flavours={content.flavours}
				display_flavour_names={content.display_flavour_names}
				selected={content.display_flavour_names && !firstStart ? -1 : selected}
				setSelected={setForcedSelection}
				setFirstStart={setFirstStart}
				disabled={moving}
			></DotNavigation>
		</>
	);
}
