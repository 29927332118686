const shrinkVariantX = {
	circles: { default: '-23vw' },
	default: {
		us: '-18vw',
		za: '-19vw',
		default: '-20vw'
	}
};

const shrinkVariantY = {
	default: { us: '-45px', za: '-50px', default: 0 },
	circles: { default: '-60px' }
};

const shrinkScale = {
	default: { za: 0.5, default: 0.6 },
	circles: { default: 0.6 }
};

const initialVariantX = {
	default: { us: '2vw', default: 0 },
	circles: { default: -100 }
};

const initialVariantY = {
	default: { us: '-2px', default: 0 },
	circles: { default: 0 }
};

const initialScale = {
	default: { default: 0.95 },
	circles: { default: 0.95 }
};

export const getShrinkVariants = (
	locale,
	displayType = 'default',
	flavourNamesDisplayed = false
) => {
	const correctionX = flavourNamesDisplayed && displayType === 'default' ? 70 : 0;
	const translationX =
		initialVariantX[displayType][locale] || initialVariantX[displayType]['default'];
	const correctTranslationX =
		typeof translationX === 'number' ? translationX - correctionX : translationX;

	return {
		initial: {
			scale: initialScale[displayType]['default'],
			x: correctTranslationX,
			y: initialVariantY[displayType][locale] || initialVariantY[displayType]['default'],

			transition: {
				delay: 0.1,
				type: 'spring',
				damping: 20,
				mass: 1.5,
				stiffness: 50
			}
		},
		shrunk: {
			scale: shrinkScale[displayType][locale] || shrinkScale[displayType]['default'],
			x: shrinkVariantX[displayType][locale] || shrinkVariantX[displayType]['default'],
			y: shrinkVariantY[displayType][locale] || shrinkVariantY[displayType]['default'],
			transition: {
				delay: 0.3,
				type: 'spring',
				damping: 20,
				mass: 1.5,
				stiffness: 50
			}
		}
	};
};
