import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	RouterTransition,
	AppWrapper,
	AgeScreener,
	useTheme,
	useSocket,
	useQueryParams,
	Warnings
} from 'bat-components';

import style from './app.module.scss';

import { Main } from 'components/pages';
// import { useLongPress }  from './../../hooks/useLongPress';
import { useTripleClick } from './../../hooks/useTripleClick';

export default function App() {
	const { listen } = useHistory();
	const { sendToServer, state: socketState } = useSocket();
	const { trial_wall_variant: screenVariation = '1' } = useQueryParams();

	const { flavours, locale, theme, totalScreens, warnings, ratingBarVisibility } = useSelector(
		state => ({
			flavours: state.content.getIn(['content', 'flavours']).toJS(),
			locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
			theme: state.content.getIn(['content', 'product', 'theme']),
			warnings: state.content.getIn(['content', 'warnings'])?.toJS(),
			ratingBarVisibility: state.content.getIn(['content', 'ratingBarVisibility']),

			// Total screens counts total number of trial screens, as they're the most important when it comes to setting up the node server
			totalScreens: state.internal.getIn(['store', 'device_totals', 'samsung_trial_tv'])
		})
	);

	const selectedItem = flavours[0];

	useTheme(theme);

	useEffect(() => {
		if (socketState === 'joinedRoom') {
			console.log('Socket state changed, re-registering screens');
			sendToServer('registerScreens', Number(totalScreens));
		}
	}, [sendToServer, socketState, totalScreens]);

	// history listener used to tell tv screen to become idle
	// when the app returns to age screener
	useEffect(() => {
		const unlisten = listen(location => {
			if (location.pathname === '/') {
				sendToServer('endAwake', {
					kioskID: screenVariation,
					kioskSpan: selectedItem.interactive_content?.span || 1
				});
			}
		});
		return unlisten;
	}, [sendToServer, screenVariation, selectedItem, listen, locale]);

	const handleLongPress = () => {
		sendToServer('appReset');
	}
	
	// const longPress = useLongPress(handleLongPress, 3000);
	const [refCallback] = useTripleClick(handleLongPress);



	return (
		<AppWrapper>
			<button className={style.appReset} ref={refCallback}></button>
			<RouterTransition>
				<Route path="/main" component={Main} />
				<Route path="/" exact render={() => <AgeScreener next="/main"></AgeScreener>}></Route>
			</RouterTransition>
			<Warnings content={warnings} ratingBarVisibility={ratingBarVisibility} />
		</AppWrapper>
	);
}
