import React from 'react';
import clsx from 'clsx';
import style from './nicotine-levels.module.scss';
import { useSelector } from 'react-redux';

// ======================================================================
// This component has different styles original_dots, dots (vertical version and horizontal) and bars.
// The nodes that you include will define which version to use
// ======================================================================

export function NicotineLevels({ item, strings, bars, textOnly }) {
	const { zero, generalBars, generalTextOnly } = useSelector(state => {
		return {
			zero: state.content.getIn(['content', 'pages', 'single', 'strings', 'zero']) ?? 'Zero',
			generalBars: state.content.getIn(['content', 'product', 'bars'])?.toJS() ?? null,
			generalTextOnly: state.content.getIn(['content', 'product', 'nic_level_text_only']) ?? false
		};
	});

	if (!item?.nic_levels) return <></>;

	// ======================================================================
	// Dots vertical and horizontal
	// ======================================================================

	if (item.nic_levels.some(nic_level => typeof nic_level === 'object')) {
		const maxColumns = item.nic_levels[0].flavour ? 2 : 3;

		return (
			<>
				{strings.available_in && <div className={style.availableText}>{strings.available_in}</div>}
				<div
					style={{ '--columns': item.nic_levels_separate_rows ? 1 : (item.nic_levels.length < 2 ? item.nic_levels.length : maxColumns) }}
					className={clsx(
						style.nicLevelsContainer,
						item.nic_levels[0]?.flavour ? style.marginTop : ''
					)}
				>
					<Dots item={item} singlerow={item.nic_levels_separate_rows} />
				</div>
			</>
		);
	}

	// ======================================================================
	// Bars version
	// ======================================================================

	if (bars || generalBars) {
		const nic_values = bars ? bars : generalBars;
		const displayTextOnly = textOnly !== undefined ? textOnly : generalTextOnly;

		const getIndexPosition = level => {
			return nic_values.findIndex(barValue => barValue === level);
		};

		return (
			<>
				<div className={style.barsContainer}>
					{item.nic_levels.map(level => (
						<div key={`${level}-nicotine-level-bars`} className={clsx(style.bars)}>
							{!displayTextOnly && (
								<div className={style.barGroups}>
									{[...Array(nic_values.length)].map((bar, index) => (
										<span
											key={`${level}-${index}-nicotine-level-bar`}
											className={clsx(
												style.bar,
												nic_values.length < 4 ? style.biggerBars : style.smallerBars,
												getIndexPosition(level) >= index ? style.full : style.empty
											)}
										></span>
									))}
								</div>
							)}
							<span
								className={clsx(
									style.barValue,
									displayTextOnly ? style.bigger : '',
									level === 0 ? style.zero : ''
								)}
							>
								{level === 0 ? zero : level}
							</span>
						</div>
					))}
				</div>
				<div className={clsx(style.variantWeight, style.bars)}>
					{strings.mg_ml ? strings.mg_ml : item.nicotine_title}
				</div>
			</>
		);
	}

	// ======================================================================
	// Default original dots
	// ======================================================================

	return (
		<>
			<div className={style.variantLevels}>
				{item.nic_levels.map(level => (
					<div className={style.variantLevel} key={level}>
						{level}
					</div>
				))}
			</div>
			<div className={style.variantWeight}>
				{strings.mg_ml ? strings.mg_ml : item.nicotine_title}
			</div>
		</>
	);
}

const Dots = ({ item, singlerow }) => {
	// ======================================================================
	// The node that defines if horizontal or vertical is flavour (flavourName)
	// ======================================================================

	const hasFlavourName = Boolean(item.nic_levels[0].flavour);
	const dotsType = hasFlavourName ? style.horizontal : style.vertical;

	return item.nic_levels.map((level, index) => {
		return (
			<div
				className={clsx(style.levelWrapper, dotsType, singlerow ? style.levelWrapperSingle : '')}
				key={level.weight || level.flavour || `dots-${index}`}
			>
				{level.flavour && <span className={style.flavourName}>{level.flavour}</span>}
				<div className={style.dots}>
					{[...Array(hasFlavourName ? 5 : 4)].map((dot, index) => (
						<div
							key={`${level.weight}-${index}`}
							className={clsx(style.dot, index < level.dots_level && style.dotFilled)}
						/>
					))}
				</div>
				{level.weight && <div className={style.variantDotsWeight}>{level.weight}mg</div>}
				{level.flavour && <span className={style.variantLevelWeight}>{level.dots_level}</span>}
			</div>
		);
	});
};
